import {Component, OnInit} from '@angular/core';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {
  title = 'imkmock';
  private token: string;

  public userTextArea: string;
  public domain: string;

  constructor() {
    document.domain = 'customerportal.captest.dk';
    this.domain = document.domain;
    console.log('imkmock document.domain: ' + this.domain);
  }

  ngOnInit() {
    this.IMKGetToken();
  }

  IMKGetToken(): string {
    this.token = (window as any).parent.getKPToken();
    const tokenString = JSON.stringify(this.token);
    console.log('Imk got this from parent window KP: ' + tokenString);
    // const elementById = document.getElementById('user-value');
    // elementById.textContent = tokenString;
    this.userTextArea = tokenString;
    return tokenString;
  }
}
